import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import React, { useCallback, useLayoutEffect, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const {
  COMMENT_TARGET_TYPES: { TRANSACTIONS }
} = DataConstants;

const LastComment = (props) => {
  const {
    comments,
    lastComment,
    transactionId
  } = props;

  const { unread: unreadCommentsCount = 0 } = comments;

  const [{ editItem }] = useEnvVars();

  const [searchTransactionId, sectionName] = (editItem || "").split(".");

  const allUsersData = useSelector(getAllUsersData);

  const { uiTexts } = useSelector(getTextsData);

  const [dropdownOpened, setDropdownOpened] = useState(false);

  const foundUser = lastComment ? Utils.arrayFindById(allUsersData, lastComment.createdBy) : null;

  const handleCloseClick = useCallback(() => {
    setDropdownOpened(false);
  }, []);

  const handleShowMoreClick = useCallback(() => {
    setDropdownOpened(true);
  }, []);

  useLayoutEffect(() => {
    setDropdownOpened(searchTransactionId === transactionId && sectionName === "comments");
  }, [searchTransactionId, transactionId, sectionName]);

  return (
    <div className={Css.lastComment}>
      <DropDown opened={dropdownOpened} setOpened={setDropdownOpened}>
        <DropDownContent className={Css.dropdownContent}>
          <CommentsBlock
            compact popup
            commentTarget={TRANSACTIONS}
            commentTargetId={transactionId}
            comments={comments}
            onClose={handleCloseClick} />
        </DropDownContent>
      </DropDown>
      <div className={classNames(Css.content, !!unreadCommentsCount && Css.hasUnread)} onClick={handleShowMoreClick}>
        <div className={Css.row}>
          <div className={Css.textShort}>
            <Icons.ChatText weight={dropdownOpened ? "fill" : "regular"} />
            <span>{lastComment.text}</span>
          </div>
          <div className={Css.textFull}>
            <Icons.ChatText weight={dropdownOpened ? "fill" : "regular"} />
            <span>{lastComment.text}</span>
          </div>
        </div>
        <div className={Css.row}>
          <div className={Css.reply}>
            <Icons.ArrowBendUpRight />
            <span>{uiTexts.replyTo}</span>
          </div>
          <AutoTooltip>
            <div className={Css.createdBy}>
              {`${foundUser ? foundUser.fullName : lastComment.createdByEmail}, ${
                moment.duration(moment(lastComment.createdAt).diff(moment())).humanize(true)
              }`}
            </div>
          </AutoTooltip>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LastComment);
