import Css from "./style.module.scss";

import alexAvatar from "assets/alexAvatar.png";

import partyPopper from "assets/emojiPartyPopper.png";

import { checkIsBusinessUser, getUserData } from "selectors/user";
import { getProjectName } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Avatar from "nlib/ui/Avatar";
import Constants from "const/Constants";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";
import objectHash from "object-hash";

const TransactionsIntroModal = () => {
  const { messages } = useSelector(getTextsData);

  const projectName = useSelector(getProjectName);

  const businessUser = useSelector(checkIsBusinessUser);

  const userData = useSelector(getUserData);

  const introsLsKey = useMemo(() => {
    const hash = objectHash({ v: userData.email });

    return `${Constants.LS_KEYS.EXCLUDED_INTROS}_${hash}`;
  }, [userData.email]);

  const [modalShown, setModalShown] = useState(!Utils.storageValue(introsLsKey));

  const handleClose = useCallback(() => {
    setModalShown(false);
    Utils.storageValue(introsLsKey, true);
  }, [introsLsKey]);

  if (!businessUser || !introsLsKey || !modalShown) return null;

  return (
    <Modal
      className={Css.transactionsIntroModal}
      iconComponent={null}
      title={(
        <div className={Css.title}>
          <Avatar className={Css.avatar} src={alexAvatar} />
          <span>Alex</span>
        </div>
      )}
      onClose={handleClose}>
      <div className={Css.modalContent}>
        <div className={Css.large}>
          {Utils.replaceTextVars(messages.introModalGreeting, { projectName })}&nbsp;<img src={partyPopper} />
        </div>
        <p>{messages.introModalMessageA}</p>
        <p>{messages.introModalMessageB}</p>
      </div>
      <div className={Css.video}>
        <iframe
          src="https://player.vimeo.com/video/679940425?title=0&byline=0&portrait=0"
          allowFullScreen />
      </div>
    </Modal>
  );
};

export default React.memo(TransactionsIntroModal);
